let bb = `15951771438
18951702355
19852831313
15295752913
15996400508
13182837106
18951031018
18305193236
13951800443
17502555515
13338606919
13505196993
15951949510
18795854044
15850647450
18251872033
18105150119
18913037069
15861807074
18851976118
15161480139
19551953133
19505173307
13813085849
18795838542
18452602804
13305182762
18951705744
18251882708
13814060790
13451838700
17368693558
15251707152
15261425542
15861826676
17761712201
18028676649
18751937696
13605147786
17712423863
18251558923
15996434551
18115460335
13951078149
19536433703
18851712317
13851904955
18100609932
13584076781
15951771322
13675175949
15150547727
13814014344
18852031982
18913322595
15951859461
19505179108
15852027068
15952007564
15005181547
13770590179
15996230087
13913830226
18912941130
18305193228
18752148155
15005142470
15950462959
15365193119
18752006602
18851699898
15150625138
18851897946
15077834584
18852010698
18205816292
18851691236
13814067276
13913397844
15062265873
15951793897
15094309082
13357832767
17351006264
18013801219
18013808087
17768143823
17625921126
18151683907
17826064719
17749541226
18913974366
15996203118
15996348221
15150684417
13390780902
15151883872
17849935382
13601438134
18994090276
18724013820
18651697058
18852005729
15895910926
19825811852
13814040651
13952074098
13376063730
13327815234
19852831381
18261929504
17705176092
15952077825
13405587700
15951005521
13400071345
18851847429
15996344072
15805177146
13770836100
18652981880
13851840823
19852815362
15051809165
18851712672
15261870398
15365073089
18851691262
19850887116
17826501448
18261929986
18305192668
13773835840
18051087585
15895924513
17302546814
17361887513
18951070668
18305164678
17849912206
19951718872
15861812282
18136862652
15050557073
15152631202
19825046321
18252047330
13805248069
19895896968
19848346860
18020158109
15651803807
18115645758
15261481283
15951621268
18260007683
18761862816
13404163907
18261931892
15195880962
13512513980
13051569861
19118442972
15951800639
18951624815
19850444597
17714353751
13295238983
15240023644
18305192867
13601460480
18118951020
17767032137
13222001158
18795935445
15851824672
18798938065
18795836385
15850626391
15951620027
18342701963
17384476082
19852816773
18952057399
17751759009
15751855101
18252063627
15365073089
13701472556
13913838376
18252077819
17130384383
19502260991
17826087467
15715174254
13952034159
19505172353
17826085610
18061786075
15950453045
15251787971
13913349671
13404111250
15195799563
18752006163
18752006525
15951737324
18351901005
18951425046
18014841767
13115007892
15251799676
15951845529
19895898856
15051890132
18351990390
13357819802
13776624760
13813395630
17749552292
13382795802
17826048779
18061497978
13801582202
18251824440
18905164585
15195808951
15195880962
15151885670
13142882971
18052040653
19822699556
15151880596
19951930949
18852015987
13675195671
15850541173
13952079518
18914736303
13851669537
13805146924
18051083558
17768142510
19825802775
18551677080
13512528235
13512524910
15365011762
18362906463
18761630200
19822698631
18018093492
19850716592
19850444597
19911773354
13032547739
18751937696
13776416666
18261147706
18168042017
19844554315
18115126635
13401721158
19951933958
18362407313
13813945292
17766082790
15190455143
18851949076
13222047392
15312029004
13401997753
15335181469
13814199884
15715195768
17826068369
13851870109
13951784750
18913044943
15705188723
17130496667
13951609091
15952999234
13580898419
13913011695
15195824865
19951942114
13913315227
13815867974
13770955849
13022533713
13245867326
13951947602
15295513803
17639245083
13914760700
13851533170
17714314669
13951797090
15951943646
18951680881
17749549832
17316907579
17749540931
17751756222
13951797090
13814088022
13511729836
13337721921
17366032867
17751784747
18724017769
13913015321
18262604621
19941501911
18944096229
13382049826
13770657760
19816588209
15005182357
18752006516
17505174663
18512522562
18651686875
18305192678
13851616916
17714391106
13372026667
18262626181
18014498605
13390785633
13585117205
19951664482
13951007980
18344668424
15951700946
18606199276
13655185078
18805151405
15295708794
13815421367
17749508460
19551958589
15266037545
15195789025
15062217882
15077877508
18021396546
18251845683
15371018705
19844556684
17805123174
18252012891
13851462998
18761875353
18795892302
13813050419
13379788871
13951601098
19844566496
13382099271
18651613189
13182975146
18012922670
13813852740
13260822895
13851989916
15252490126
13337724237
13770546002
18019960692
15952007564
13851616916
18722718953
17314454477
19761816246
13338617391
13912982276
18551706509
13912926011
15996312007
17766100832
17826001386
15150625423
15950492880
18851956750
13770543154
13151081135
15851823493
17749540931
17205139340
17795868901
19505162252
13276645938
18705185344
18013017381
19184369320
13805191434
18305193302
15380817065
17372753385
15205159752
13337865612
15905162922
13851909218
15720612574
13270832926
13145127825
17384467333
18105156986
18761672026
18951933949
13276647553
18362075774
17351759880
13584001323
13951890498
13222028625
18651867910
16712511118
17136280087
13851832008
15151822501
13951813994
15298384712
15715176364
15962336224
18851005597
15250977197
18321162513
13218054085
13913013391
18936043775
18205187870
19356720771
13401998705
19825045875
15151854868
18052006676
18851063736
13245805205
15850506014
13913015211
13951811668
15305159774
18752066623
15261450344
18852753988
15161761501
13042524290
15951805009
13601580041
13047552530
17602558055
13951665295
14751711827
13042553837
18913914371
15380767050
17136230627
13357828562
15715197828
13914722591
13951744383
17384485556
13951637836
13851648148
18851691586
13951030246
15895804005
13376060698
13912958748
18305191256
13022517731
13914783310
18583163876
13914490499
18851964651
18112930162
13813040298
13951962850
18261926039
17849994988
13813024084
15651023582
13912917024
17366032867
14751717856
18351584114
13376098782
18305193577
13951770554
17849988952
13951769102
15151889700
15251710682
13913011337
15952032894
15366179357
18705213518
13851844219
13805188833
17302597162
13390775361
13913377598
13952093161
15195820430
13770885254
18851934592
13400078882
18013851906
15371013318
13770961322
15251834213
15301587970
18912974159
13814124261
13813037647
13951842921
18851718682
13913889660`


let getPhones = () => {
    console.log(1212)
    console.log(bb)
    let arr_b = bb.split('\n')
    let result_arr = []
    console.log(1215, arr_b)
    for (let b of arr_b) {
        b = b.replace(/\s/g, "");
        result_arr.push(`"${b}"`)
    }
    console.log(734, result_arr.join(','))
    return result_arr.join(',')
}
let arr = getPhones()
export default arr